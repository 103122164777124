import { mapGetters } from 'vuex';

const ContextMenuMixin = {
  data(){
    return{
      isContextMenu:false,
      contextMenuCssProps:{
        '--top':0,
        '--left':0
      },
      contextMenuRowData:{},
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth' ]),
  },
  methods: {
    onClickContextMenu(e, categoryId, rowData){
      e.preventDefault();
      this.$emit('onClickCategoryItem', categoryId, rowData, true);
      const contextMenuWidth = 280;
      const isOverflowViewLeft = e.pageX + contextMenuWidth > this.getWindowWidth;
      const left = isOverflowViewLeft ? e.pageX - contextMenuWidth : e.pageX;
      this.contextMenuRowData = rowData;
      this.contextMenuCssProps['--top'] = e.pageY+'px';
      this.contextMenuCssProps['--left'] = left+'px';
      this.isContextMenu = true;
      window.addEventListener('click',this.onContextMenuBlur,true)
      // window.addEventListener('contextmenu',this.onContextMenuBlur,true)
    },
    onContextMenuBlur(e){
      // if(e.path.findIndex(item => item.id === 'boxContextMenu') < 0){
        window.removeEventListener('click',this.onContextMenuBlur,true)
        // window.removeEventListener('contextmenu',this.onContextMenuBlur,true)
        this.removeContextMenu();
      // }
    },
    removeContextMenu(){
      // console.log('removeContextMenu')
      this.isContextMenu = false;
      this.contextMenuCssProps['--top'] = '0px';
      this.contextMenuCssProps['--left'] = '0px';
      // this.contextMenuRowData = {};
    }
  }
}
export default ContextMenuMixin;