<template>
  <Popup
    title="추천 포스트 관리"
    :maxWidth="500"
    closeBtnText="닫기"
    compeleteBtnText="저장"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',categoryData.dataList)">
    <GroupCategory
      :groupCategoryData.sync="categoryData"
      :dataList.sync="categoryData.dataList"
      :value.sync="categoryData.value">
      <template v-slot:item_category="slotProps">
        <span class="txt_item">
          {{ slotProps.categoryForIndex+1 }}
        </span>
        <strong class="tit_item fill_item">
          <Selectbox
            :dataList="selectPostDataList(slotProps.itemData.link)"
            placeholder="포스트를 선택하세요"
            :value="slotProps.itemData.link"
            thumnailKey="thumbnail"
            @update:value="value => onUpdatePostRecommedPopup(value,slotProps.categoryForIndex)"/>
        </strong>
      </template>
    </GroupCategory>
    <p class="desc_info">💡 최신 등록일 순서로 자동 정렬됩니다.</p>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory'
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name:'PostRecommedPopup',
  props:{
    allPostDataList:Array,
    hotDataList: Array
  },
  components:{
    Popup,
    GroupCategory,
    IconSvg,
    Selectbox,
    Button
  },
  mounted(){
    this.init();
  },
  data(){
    return{
      categoryData: {
        title : '',
        id : 'FaqCategory',
        index : 0,
        titleList: [
          { title:'No.' },
          { title:'포스트 제목', isFill: true },
        ],
        dataList: [],
        isDragable:false,
        value:null
      },
      allPostDataListSync:[]
    }
  },
  methods:{
    init(){
      this.categoryData.dataList = JSON.parse(JSON.stringify(this.hotDataList));
      let allPostDataListSync = JSON.parse(JSON.stringify(this.allPostDataList));
      this.allPostDataListSync = allPostDataListSync.map(item => {
        let rObj = Object.assign({},item);
        rObj.id = item.link;
        rObj.currentId = item.id;
        rObj.text = item.title;
        return rObj;
      });
    },
    selectPostDataList(ownLink){
      let result = this.allPostDataListSync.filter(item => item.link === ownLink || !this.categoryData.dataList.find(hotData => hotData.link === item.link));
      // const resultSetSelectDataList = this.$options.filters.setSelectDataList(result,'link','title');
      if(ownLink){
        return this.$options.filters.addDataListAll(result,'','선택안함');
      }else{
        return result;
      }
    },
    onUpdatePostRecommedPopup(newLink,index){
      const new_link = newLink;
      const old_link = this.categoryData.dataList[index].link;
      if(new_link === old_link) return;
      if(new_link){
        let updateData = this.allPostDataListSync.find(item => item.link === new_link);
        if(updateData){
          this.categoryData.dataList[index] = updateData;
        }
      }else{
        this.categoryData.dataList[index] = {
          link:'',
          createdAt : null
        }
      }
      this.categoryData.dataList.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      });
      this.categoryData.dataList.map((item,index) => {
        let rObj = item;
        if(!item.link){
          rObj.id = `new${index}`
        }
        return rObj;
      })
    }
  }
}
</script>
<style scoped>
.area_category::v-deep .list_category{flex:1 0;border-bottom:0 none}
.area_category::v-deep .list_category .inner_item{line-height:38px}
.area_category::v-deep .list_category li .selectbox_comm{display:block}

.area_category + .desc_info{margin-top:20px}
</style>