import PostModel from '@/views/landing/blog/post/model/PostModel';
import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class PostListViewModel {
  constructor() {
    this.model = new PostModel();
    this.boardData = {
      title:'포스트 목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'이미지',
          width:'50px',
          value:'shareThumbnail',
          img:{
            width:'36px',
            height:'36px',
            position:{
              x: 'center',
              y:'0'
            },
            size:'cover'
          },
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isEllip: true,
          isAlignLeft: true
        },
        // {
        //   title:'내용',
        //   width:'',
        //   value:'contents',
        //   isEllip: true,
        //   isAlignLeft: true
        // },
        {
          title:'추천<br/>포스트',
          width:'70px',
          value:'postType',
          filter: { name:'convertIdToText', value:'post_type' },
          badge: { badgeColorFilter:{ name:'convertIdToColor', dataListName:'post_type' }, badgeFill: false }
        },
        {
          title:'링크명',
          width:'200px',
          value:'link',
          isAlignLeft: true,
          isBreakAll: true,
        },
        {
          title:'조회수',
          width:'70px',
          value:'viewCount',
          isAlignRight: true,
          emptyValueText:'-'
        },
        {
          title:'상태',
          width:'80px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'blog_status'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'blog_status'
            },
            badgeFill: false
          },
        },
        {
          title:'최종수정일시',
          width:'134px',
          value:'updatedAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isMobileTitle:true
        },
        {
          title:'링크복사',
          width:'150px',
          value:'status',
          buttonCondition: [
            { condition:'NORMAL', text:'게시글', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickCopy(data) },
            { condition:'NORMAL', text:'본문만', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickCopyChildSafeUrl(data) },
          ],
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.searchDataList= [
      {
        title: '검색어',
        type: 'Input',
        isFull: true,
        id: 'keyword',
        value: '',
      },
    ];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };

    this.isPostRecommedPopup = false;
    this.putPostTypeCount = null;
    this.putPostTypeCountCheck = null;
    this.hotDataList = [];
  }

  init(query){
    const { pageSize, page } = query;
    if(pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.searchParams.page = Number(page);
    }
    this.getPostList();
    this.getHotPostList();
  }
  getPath(link) {
    const domain =
      process.env.NODE_ENV === 'development'
        ? 'https://www.dev.firfin.family'
        : 'https://www.firfin.family';
    const path = '/blog/post/detail/';

    return `${domain}${path}${link}`;
  }
  onClickCopy(rowData){
    const linkData = this.getPath(rowData.link);
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `포스트 링크가 복사되었습니다`);
    });
  }
  onClickCopyChildSafeUrl(rowData){
    const linkData = `${this.getPath(rowData.link)}?isChildSafe=true`;
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `포스트 링크가 복사되었습니다`);
    });
  }
  onClickRow(rowId,rowData){
    router.push({
      name: 'LANDING_BLOG_POST_DETAIL',
      params: { link : rowData.link},
      query : {
        pageSize : this.searchParams.pageSize,
        page : this.searchParams.page,
      }
    });
  }
  onSearch(){
    this.getPostList();
  }
  getPostList(){
    const query = makeQueryStringByObject(this.searchParams);
    const searchKeyword = this.searchDataList[0].value;
    const path = `${
      searchKeyword
        ? apiPath.POST_LIST_SEARCH.format(searchKeyword)
        : apiPath.POST_LIST
    }${query}`;

    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getHotPostList(){
    const path = `${apiPath.POST_HOT_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      let resultData = success.data.map((item,index) => {
        let rObj = item;
        rObj.index = index+1;
        return rObj
      });
      if(resultData.length < 6){
        const addDataLength = 6 - resultData.length;
        let newIndex = resultData.length +1;
        for(var i = 0; i < addDataLength; i++){
          let newData = {
            id : `new${i}`,
            link : '',
            createdAt : null
          }
          resultData.push(newData)
          newIndex += 1;
        }
      }
      this.hotDataList = resultData
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  onClickPostRecommedPopup(){
    this.getHotPostList();
    this.isPostRecommedPopup = true;
  }
  onClickClosePostRecommedPopup(){
    this.isPostRecommedPopup = false;
  }
  onUpdatePostRecommedPopup(value,index){
    // console.log('onUpdatePostRecommedPopup',value,index);
    // const old_link = this.hotDataList[index-1].link;
    // const new_link = value;
  }
  onClickCompletePostRecommedPopup(result){
    const old_list = JSON.parse(JSON.stringify(this.hotDataList));
    const new_list = JSON.parse(JSON.stringify(result));
    const addList = new_list.filter(item => item.link && !old_list.find(hotData => hotData.link === item.link));
    const removeList = old_list.filter(item => item.link && !new_list.find(hotData => hotData.link === item.link));
    if(addList.length > 0){
      addList.forEach(addItem => {
        addItem.id = addItem.currentId;
        this.putPostType(addItem,'ADD')
      })
    }
    if(removeList.length > 0){
      removeList.forEach(removeItem => {
        this.putPostType(removeItem,'REMOVE')
      })
    }
    if(addList.length === 0 && removeList.length === 0){
      this.isPostRecommedPopup = false;
    }else{
      this.putPostTypeCount = addList.length + removeList.length;
    }
  }
  // 수정
  putPostType(rowData,status){ // status : 'ADD','REMOVE';
    const path = `${apiPath.POST_MODIFY.format(rowData.id)}`;
    let data = this.model.setPostTypeModifyData(rowData,status);
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.putPostTypeCountCheck += 1;
      if(this.putPostTypeCount === this.putPostTypeCountCheck){
        store.dispatch('commonToast/fetchToastStart', '추천 포스트가 수정되었습니다');
        this.isPostRecommedPopup = false;
        this.putPostTypeCount = null;
        this.putPostTypeCountCheck = null;
        this.getPostList();
        this.getHotPostList();
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}